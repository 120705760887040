.departments--table {
    @apply mx-[245px] block;

    > .header {
        @apply w-full grid border-grayLight;
        grid-template-columns: 1fr 1fr 1.7fr 1.2fr;
        border: solid 2px;

        > .text {
            @apply py-[15px] uppercase text-grayLight text-center text-s29 font-bold;

            &:nth-of-type(3) {
                @apply text-left;
            }
        }
    }

    > .row {
        @apply py-[4px] grid items-center border-grayLight;
        grid-template-columns: 1fr 1fr 1.7fr 1.2fr;
        border: solid 2px;
        border-top: none;

        &.--active {
            @apply text-black bg-white;
        }

        > .minutes {
            @apply w-[170px] leading-[170px] rounded-full text-center text-s36 inline-block font-bold mx-auto;

            &.--green {
                @apply bg-green text-black;
            }

            &.--red {
                @apply bg-red text-white;
            }

            &.--yellow {
                @apply bg-yellowSecondary text-black;
            }
        }

        > .number {
            @apply  w-[314px] py-[20px] leading-[1] text-center text-s90 bg-black text-center text-white font-bold mx-auto;
        }

        > .planned-time {
            @apply leading-[1] text-center text-s90;
        }

        > .real-time {
            @apply leading-[1] text-center text-s90 font-bold;
        }

        > .route {
            @apply text-s31 font-bold;
        }
    }
}