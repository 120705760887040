.chart--table {
    @apply gap-[20px] grid grid-cols-2 relative;

    > .charts--block {
        @apply absolute right-0 top-0 h-full grid h-full;
        width: calc(50% - 10px);
        grid-template-rows: 1fr auto 1fr auto auto;

        > .chart {
            @apply bg-ashen ;
        }

        > .description {
            @apply px-[22px] py-[6px] mt-[-10px] uppercase w-full text-s24 font-bold;

            &.--top {
                @apply mb-[20px] text-black bg-white;
            }

            &.--bottom {
                @apply text-black bg-yellowPrimary;
            }
        }

        > .labels {
            @apply flex flex-wrap justify-between bg-black text-s30 font-bold;
        }
    }

    > .values--info {
        @apply gap-[20px] grid grid-cols-1;
        grid-template-rows: 1fr 0.75fr 0.5fr;

        > .double--box {
            @apply gap-[20px] grid;
            grid-template-columns: auto auto;
        }

        .value {
            @apply px-[70px] py-[70px] flex flex-col w-full uppercase justify-center;

            &.-big {
                @apply leading-[1] bg-gray text-s230 text-dimGray font-bold;

                > span {
                    @apply text-s36;
                }
            }

            &.-medium {
                @apply leading-[1] bg-silver text-s190 text-white font-bold;

                > span {
                    @apply text-s31;
                }
            }

            &.-small {
                @apply leading-[1] bg-yellowPrimary text-s130 text-dimGray font-bold;

                > span {
                    @apply text-s31;
                }
                
                &:last-of-type {
                    @apply bg-yellowSecondary;
                }
            }
        }
    }
}