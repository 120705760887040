.header--component {
    @apply mb-[45px] flex flex-row w-full justify-between items-center;

    > .counter {
        @apply text-s36 font-medium;

        > span {
            @apply text-yellowPrimary font-semibold;
        }
    }

    > .date {
        @apply text-yellowPrimary text-s36;
    }

    > .shift {
        @apply uppercase text-s36;
    }

    > .time {
        @apply text-yellowPrimary text-s90 font-bold;
    }

    > .type {
        @apply text-yellowPrimary text-s29 font-bold uppercase;
    }
}